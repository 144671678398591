const FIXED_CONTROLS_RIGHT = document.querySelector(".fixed-controls-right");
let lastScroll = 0;

export default window.addEventListener("scroll", (e) => {
    if(FIXED_CONTROLS_RIGHT) {
        lastScroll = window.scrollY;

        if(lastScroll > 150) {
            FIXED_CONTROLS_RIGHT.classList.add("js-change-position-top");
        } else {
            FIXED_CONTROLS_RIGHT.classList.remove("js-change-position-top");
        }
    }
});
