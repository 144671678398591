import { Swiper, Navigation, Pagination, Autoplay  } from "swiper/js/swiper.esm.js";

Swiper.use([Navigation, Pagination, Autoplay ]);

export default () => {
    const mainBanner = new Swiper(".mainBanner", {
        slidesPerView: 1,
        pagination: {
            el: ".swiper-pagination",
            type: "bullets",
            clickable: true,
        },
        breakpoints: {
            600: {
                navigation: {
                    nextEl: ".swiper-button-next",
                    prevEl: ".swiper-button-prev",
                },
            },
        },
        effect: 'slide',
        longSwipes: true,
        pauseOnHover: false,
        pauseOnFocus: false,
        autoplay: true,
        autoplay: {
            delay: 3000,
            disableOnInteraction: false
        },
        loop: true,
    });

    const fiqueLigadoBanner = new Swiper(".fiqueLigadoBanner", {
        slidesPerView: 1,
        autoplay: true,
        pagination: {
            el: ".swiper-pagination",
            type: "bullets",
            clickable: true,
        },
        breakpoints: {
            600: {
                slidesPerView: 3,
                navigation: {
                    nextEl: ".swiper-button-next",
                    prevEl: ".swiper-button-prev",
                },
            },
        },
    });
    // window.addEventListener("load", () => {
        
    // });
};
