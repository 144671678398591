export default () => {
    const BUTTON_SEARCH = document.querySelector(".buttonMainSearch-mobile");
    const INPUT_SEARCH = document.querySelector(".inputMainSearch-mobile");

    const inputIsEmpty = (inputElem) => inputElem.value === "";

    BUTTON_SEARCH.addEventListener('click', e => {
        if(inputIsEmpty(INPUT_SEARCH)) {
            e.preventDefault();
            return INPUT_SEARCH.classList.toggle('is-active');
        }
    });
};
